import { Card, CardContent } from "@/components/ui/card"

export function JioHotstarPageComponent() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-700 to-purple-700 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-white mb-2">OnlyJaans</h1>
        <p className="text-xl text-white mb-8">Sumedha Uppal, If you could help...</p>
        
        <div className="grid md:grid-cols-2 gap-8">
          <Card className="bg-white text-black">
            <CardContent className="p-6">
              <h2 className="text-lg font-semibold mb-2">Dear Sumi,</h2>
              <p className="mb-4">I am a software  developer based in Bangalore, currently working at a startup. In early 2024, while scrolling through Twitter, I came across your tweets on my TL....</p>
              <p className="mb-4">In 2020, I was dating this fascinating girl from my university. Everything was perfect. It was a transformational experience for me. That relationship taught me many valuable lessons and provided insights into the ins and outs of relationships & women, which was amazing. However, it was limited in scope – and I had a tingling feeling that I could always do better.</p>
              <p className="mb-4">Sumedha, when I saw you, your writing & your poetry somehow, I found myself head over heels for you. I'd always dreamt of pursuing you but I thought I could never afford to. It's Sumedha Uppal after all. </p>
     </CardContent>
          </Card>
          
          <Card className="bg-white text-black">
            <CardContent className="p-6">
            <p className="mb-4">                When I saw this domain become available and your tweet about OnlyJaans, I felt things might just fall into place. My intention of buying this domain was simple: if you actually end up setting up a bts channel of your life, I might be able to fulfill my dream of dating you.</p>
            <p className="mb-4">To acquire this domain, kindly reach out to mail@onlyjaans.com from your company email address with an official love letter attached, stating your authorization to offer your heart to me. 
            
            <br/>Or perhaps, let's go on a cute date to start with? For a sweetheart like you, this will be a minor expense, but for me, the sale of this domain would be truly life-changing.</p>
              <p>Best Regards,<br />A Jhalla.</p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}